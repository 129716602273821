import React from "react";
import ReactDOM from "react-dom/client";

import 'bootstrap-icons/font/bootstrap-icons.css';

import { BrowserRouter } from "react-router-dom";

import "./locales/i18n.js";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);
