import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./components/Home";
import Pricing from "./components/Pricing";
import Contact from "./components/Contact";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Contact" element={<Contact />} />

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
};

export default App;
