import React from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import Header from "../components/sub-components/Header";
import Footer from "../components/sub-components/Footer";

import CarouselSlideImageNo1 from "./media/photos/carousel/Image1.jpeg";
import CarouselSlideImageNo2 from "./media/photos/carousel/Image2.jpeg";
import CarouselSlideImageNo3 from "./media/photos/carousel/Image3.JPG";

import "./sub-components/css/home.css";

const Home = () => {
	const page_name = "Home";
	const { t } = useTranslation();

	const navigate = useNavigate();

	const handle_contact_redirect = () => {
		navigate("/Contact");
	};

	const handle_pricing_redirect = () => {
		navigate("/Pricing");
	};

	return (
		<>
			<Header page_name={page_name} />

			<main>
				<div id="myCarousel" className="carousel slide mb-6" data-bs-ride="carousel">
					<div className="carousel-indicators">
						<button
							type="button"
							data-bs-target="#myCarousel"
							data-bs-slide-to="0"
							className="active"
							aria-current="true"
							aria-label="Slide 1"
							style={{ backgroundColor: "white" }}></button>
						<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2" style={{ backgroundColor: "white" }}></button>
						<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3" style={{ backgroundColor: "white" }}></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img
								src={CarouselSlideImageNo1}
								className="d-block w-100"
								alt="Moderný exteriér terasy s veľkými oknami od podlahy po strop a hliníkovými žalúziami. Stredná časť zobrazuje proces čistenia, kde sú na drevenej podlahe rozložené čistiace nástroje vrátane tlakovej umývačky, vedier a čistiacich prostriedkov. Pravý obrázok ukazuje pohľad z vnútra, kde sú žalúzie čiastočne zatiahnuté, s výhľadom na čistý a udržiavaný priestor. Profesionálna čistiaca služba zabezpečuje dôkladnú starostlivosť o exteriér aj interiér."
							/>
							<div className="container">
								<div className="carousel-caption text-start text-light">
									<h1 className="display-4 text-body-emphasis"> {t("home-carousel-h1-slide1", { ns: "home" })} </h1>
									<p className="opacity-80">{t("home-carousel-p-slide1", { ns: "home" })}</p>
									<p>
										<button className="btn btn-lg btn-primary" onClick={handle_pricing_redirect}>
											{t("home-carousel-btn-slide1", { ns: "home" })}
										</button>
									</p>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<img
								src={CarouselSlideImageNo2}
								className="d-block w-100"
								alt="Čistý interiér moderných priestorov s dreveným stropom a veľkými sklenenými stenami. V strednej časti vidíme miestnosť s knihovňou a usporiadanými stoličkami, pripravenú na prezentáciu. Exteriér budovy na pravej strane zobrazuje vonkajšiu terasu s modernými presklenými stenami a čistými dlaždicami. Profesionálna čistiaca služba sa stará o dôkladnú údržbu vnútorných aj vonkajších priestorov."
							/>
							<div className="container">
								<div className="carousel-caption text-light">
									<h1 className="display-4 text-body-emphasis"> {t("home-carousel-h1-slide2", { ns: "home" })} </h1>
									<p className="opacity-80">{t("home-carousel-p-slide2", { ns: "home" })}</p>
									<p>
										<button className="btn btn-lg btn-primary" onClick={handle_contact_redirect}>
											{t("home-carousel-btn-slide2", { ns: "home" })}
										</button>
									</p>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<img
								src={CarouselSlideImageNo3}
								className="d-block w-100"
								alt="Moderný interiér administratívnej budovy s veľkými presklenými stenami a minimalistickým dizajnom. Pohľad z poschodia ukazuje priestrannú halu s vysokými stropmi a čistými podlahami. V priestore prebieha inštalácia nábytku, pričom všetko je pripravené na dôkladné čistenie a údržbu. Profesionálne čistiace služby zabezpečujú čistotu a upratanosť komerčných priestorov."
							/>
							<div className="container">
								<div className="carousel-caption text-end text-light">
									<h1 className="display-4 text-body-emphasis"> {t("home-carousel-h1-slide3", { ns: "home" })} </h1>
									<p className="opacity-80">{t("home-carousel-p-slide3", { ns: "home" })}</p>
									<p>
										<button className="btn btn-lg btn-primary" onClick={handle_contact_redirect}>
											{t("home-carousel-btn-slide3", { ns: "home" })}
										</button>
									</p>
								</div>
							</div>
						</div>
					</div>
					{/* Uncomment for carousel controls if needed */}
					{/* 
					<button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
							<span className="carousel-control-prev-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Previous</span>
					</button>
					<button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
							<span className="carousel-control-next-icon" aria-hidden="true"></span>
							<span className="visually-hidden">Next</span>
					</button>
					*/}
				</div>

				<div className="container my-5 text-center">
					<h1 className="display-3 mb-4"> {t("about-us-h1", { ns: "home" })} </h1>
					<p className="lead mb-4">{t("about-us-p-lead", { ns: "home" })}</p>
					<p className="mb-5">{t("about-us-p", { ns: "home" })}</p>
				</div>

				<div class="container my-5">
					<div class="jumbotron-custom p-5 text-center bg-body-tertiary rounded-3">
						<div class="d-inline-flex gap-4 mb-5"></div>
						<h1 class="text-body-emphasis">{t("picture-overlay-h1", { ns: "home" })}</h1>
						<p class="col-lg-8 mx-auto fs-5">{t("picture-overlay-p", { ns: "home" })}.</p>
						<div class="d-inline-flex gap-2 mb-5"></div>
					</div>
				</div>
			</main>

			<Footer />
		</>
	);
};

export default Home;
