import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Logo from "../media/logo/logo-transparent-from-black-1000px.png";

const Header = ({ page_name }) => {
	const { t, i18n } = useTranslation();
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const toggleDropdown = () => {
		setDropdownVisible(!dropdownVisible);
	};

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setDropdownVisible(false);
	};

	return (
		<header className="container header">
			<div className="d-flex flex-wrap justify-content-center py-3 mb-1">
				<NavLink to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
					<img src={Logo} alt="Logo of the webpage" className="bi me-2" width="40" height="40" />
				</NavLink>

				<ul className="nav nav-pills">
					<li className="nav-item">
						<NavLink to="/" className={`nav-link ${page_name === "Home" ? "active" : ""}`} aria-current={page_name === "Home" ? "page" : undefined}>
							{t("header-btn-home", { ns: "navigation" })}
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							to="/Pricing"
							className={`nav-link ${page_name === "Pricing" ? "active" : ""}`}
							aria-current={page_name === "Pricing" ? "page" : undefined}>
							{t("header-btn-pricing", { ns: "navigation" })}
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							to="/Contact"
							className={`nav-link ${page_name === "Contact" ? "active" : ""}`}
							aria-current={page_name === "Contact" ? "page" : undefined}>
							{t("header-btn-contact", { ns: "navigation" })}
						</NavLink>
					</li>
					<li className="nav-item dropdown position-relative">
						<div className="nav-link" onClick={toggleDropdown} style={{ cursor: "pointer" }}>
							<i className="bi bi-globe2"></i>
						</div>
						{dropdownVisible && (
							<div
								className="dropdown-menu position-absolute top-100 start-50 translate-middle-x mt-2 shadow-lg"
								style={{ zIndex: 1050, display: "block" }}>
								<ul className="list-unstyled p-2 mb-0">
									<li>
										<div className="dropdown-item rounded-2 text-center" onClick={() => changeLanguage("sk")} style={{ cursor: "pointer" }}>
											{t("dropdown-lang-sk", { ns: "navigation" })}
										</div>
									</li>
									<li>
										<div className="dropdown-item rounded-2 text-center" onClick={() => changeLanguage("en")} style={{ cursor: "pointer" }}>
											{t("dropdown-lang-en", { ns: "navigation" })}
										</div>
									</li>
								</ul>
							</div>
						)}
					</li>
				</ul>
			</div>
		</header>
	);
};

export default Header;
